<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 12px;" >
      <h5 class="card-title">Fabric Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>
      <p/>
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Group:</label>
          <div class="col-md-10">
            <select id="cmbgroup" class="form-control" v-if="item" v-model="item.type" autofocus required>
              <option value="WovenFabrics">Woven Fabric</option>
              <option value="KnittedFabrics">Knitted Fabric</option>
            </select>
          </div>
        </div>


<!--        <div class="form-group row">-->
<!--          <label class="col-md-2 col-form-label">Name:</label>-->
<!--          <div class="col-md-10">-->
<!--            <input type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="item" v-model="item.name" required>-->
<!--          </div>-->
<!--        </div>-->


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Quality:</label>
          <div class="col-md-4">
            <select id="cmbquality" class="form-control" v-if="item" v-model="item.quality_id">
              <option v-for="design in qualities" v-bind:value="design.id">
                {{ design.name }}
              </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Color:</label>
          <div class="col-md-5">
            <select id="cmbcolor" class="form-control" v-if="item" v-model="item.color_id" >
              <option v-for="color in colors" v-bind:value="color.id">
                {{ color.name }}
              </option>
            </select>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Unit of measure:</label>
          <div class="col-md-4">
            <select class="form-control" disabled >
              <option value="1">Nos</option>
              <option value="2">Kgs</option>
              <option value="3" selected>Mts</option>
            </select>
          </div>

          <label class="col-md-2 col-form-label">Weight / Mt:</label>
          <div class="col-md-4">
            <input type="number" class="form-control text-right" placeholder="Item Weight/Mt" min="0" v-if="item" v-model="item.weight">
          </div>


        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'FabricForm',
    data () {
      return {
        item: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}'),
        groups: [],
        colors: [],
        qualities:[]
      }
    },
    props: {
      myitem: {
        type: Object,
        required: false,
        default: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
      }
    },
    beforeMount () {
      this.item = this.myitem; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      this.$data.item = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
    },
    mounted () {
      const self = this;
      $('.form-control-select2').select2()

      self.loadqualities();
      self.loadColors();
    },
    methods: {
      closeWindow(){
        this.$emit('fabric_window_closed');
      },
      clear(){
        this.$data.item = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
      },
      loadAll(){
        this.loadBrands();
        this.loadCategories();
      },
      loadQualities(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.qualities = [];
          fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(Array.isArray(resp.data)){
              self.$data.qualities = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      loadColors(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(resp.data != null){
              self.$data.colors = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      saveDocument(){
        const self = this;

        self.$data.item.name = $('#cmbquality option:selected').text() + " " + $('#cmbcolor option:selected').text();

        self.$data.item.unit.id =3;

        self.$data.item.weight = parseFloat(self.$data.item.weight);

        if(self.$data.item.group.id < 1){
          alert("Invalid Group");
          $('#cmbgroup').focus();
          return
        }else if(self.$data.item.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if (self.$data.item.quality.id < 1){
          alert("Invalid Quality");
          $('#cmbquality').focus();
          return
        }else if (self.$data.item.color.id < 1){
          alert("Invalid Color");
          $('#cmbcolor').focus();
          return
        }

        self.$data.item.brand_id = 1;
        self.$data.item.group_id = 1;
        // self.$data.item.group_id = 1;
        self.$data.item.min_qty = parseFloat(self.$data.item.min_qty);
        self.$data.item.max_qty = parseFloat(self.$data.item.max_qty);


        const requestOptions = {
          method:  (self.$data.item.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.item)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}api/item`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500  });
            // self.item = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}');
            self.$emit('fabric_saved', resp.data);
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
